<template>
  <div id="Parametres">
    <v-container>
      <dashboard-core-app-bar />
    </v-container>
    <v-row style="margin-top: 20px">
      <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
      <v-col cols="12" md="12">
        <v-card class="pa-3" outlined tile>
          <v-card-title>Mercanews</v-card-title>
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="tokenApi"
                    label="api facebook"
                    :rules="rule"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="idInstagram"
                    label="id instagram"
                    :rules="rule"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="idFacebook"
                    label="id facebook"
                    :rules="rule"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-file-input
                    accept="image/png, image/jpeg"
                    prepend-icon="mdi-camera"
                    label="logo mercanews"
                    v-model="image"
                  ></v-file-input>
                </v-col>

                <v-col cols="12" md="2">
                  <v-img :src="logoMercanews" width="200px"></v-img>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="publieFacebook"
                    label="Publier sur facebook"
                  ></v-checkbox>

                  <v-checkbox
                    v-model="publieInstagram"
                    label="Publier sur instagram"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <div class="d-flex flex-row-reverse">
                <v-btn
                  color="success"
                  class="mr-4"
                  style="width: 130px"
                  @click="validate"
                  >Mettre à jour</v-btn
                >
              </div>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="330">
      <v-card>
        <v-card-title class="text-h5">
          Etes vous sur de vouloir éffectuer la mise à jour du parametrage ?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialog = false">
            Annuler
          </v-btn>

          <v-btn
            color="green darken-1"
            :loading="loading"
            text
            @click="miseAJour()"
          >
            Confirmer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alert" max-width="330">
      <v-card>
        <v-card-title class="text-h5">
          Le token est different de celui stocker sur la base de donnée.
          Continuer ?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="alert = false">
            Annuler
          </v-btn>

          <v-btn
            color="green darken-1"
            :loading="loading"
            text
            @click="miseAJour()"
          >
            Confirmer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- parametre taxe mercarue -->
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-form ref="formulaire">
            <v-card-title>Mercarue</v-card-title>
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="taxe"
                    :rules="[numberRule]"
                    label="Taxe (En pourcentages %)"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <div class="d-flex flex-row-reverse">
                <v-btn
                  color="success"
                  class="mr-4"
                  style="width: 130px"
                  @click="validateChamp()"
                  >Mettre à jour</v-btn
                >
              </div></v-container
            ></v-form
          >
        </v-card>
      </v-col></v-row
    >
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-form ref="formulaire">
            <v-card-title>Solution de paiement "PAYMEE" </v-card-title>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="PaymeeAPI"
                    label="Clé API"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="NumAccount"
                    label="Numéro de compte"
                    :rules="[numberAccountRule]"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="PaymentGatewayLink"
                    label="Payment Gateway Link"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="checkPaymentLink"
                    label="Check Payment Link"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="initiatePaymentLink"
                    label="Initiate Payment Link"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="payementFileLink"
                    label="Payement File Link"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="payementResultFileLink"
                    label="Payement Result File Link"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <div class="d-flex flex-row-reverse">
                <v-btn
                  color="success"
                  class="mr-4"
                  style="width: 130px"
                  @click="dialoguePaymee = true"
                  >Mettre à jour</v-btn
                >
              </div></v-container
            ></v-form
          >
        </v-card>
      </v-col></v-row
    >
    <!-- Boite de dialogue confirmation de mise à jour de la taxe -->
    <v-dialog v-model="dialogueTaxe" max-width="340">
      <v-card>
        <v-card-title class="text-h5">
          Voulez vous vraiment mettre à jour la taxe ?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="dialogueTaxe = false"> Annuler </v-btn>

          <v-btn :loading="loading" text @click="updateTaxe()">
            Confirmer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Boite de dialogue confirmation de mise à jour de la taxe -->
    <v-dialog v-model="dialoguePaymee" max-width="340">
      <v-card>
        <v-card-title class="text-h5">
          Voulez vous vraiment Modifier de PAYMEE?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="dialoguePaymee = false"> Annuler </v-btn>

          <v-btn :loading="loading" text @click="updatePAYMEE()">
            Confirmer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import NodesName from "../../utils/nodesName";
import db from "../../../firebaseInit";
import FirebaseDB from "../../utils/firebaseDB";
import { firebase } from "@firebase/app";
import firebaseForAuth from "firebase";

export default {
  name: "Parametres",
  components: {
    DashboardCoreAppBar: () =>
      import("../../materialDashboard/components/core/AppBar.vue"),
  },
  data() {
    return {
      dialoguePaymee: false,
      PaymeeAPI: null,
      PaymentGatewayLink: null,
      NumAccount: null,
      checkPaymentLink: null,
      initiatePaymentLink: null,
      payementFileLink: null,
      payementResultFileLink: null,
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/",
        },
      ],
      rule: [(v) => !!v || "Ce champ est requis"],
      dialog: false,
      valid: true,
      reussi: false,
      apiFacebook: "",
      idFacebook: "",
      idInstagram: "",
      publieFacebook: false,
      publieInstagram: false,
      tokenApi: null,
      logoMercanews: null,
      tokenCompare: null,
      alert: false,
      image: null,
      loading: false,
      taxe: null,
      dialogueTaxe: false,
      numberRule: (v) => {
        if (!!v && v >= 0 && v <= 100) return true;
        return "Le champ taxe doit être un pourcentage entre 0% et 100%";
      },
      numberAccountRule: (v) => {
        if (!v) return "Ce champ est recquis";
        if (!/^\d+$/.test(v)) return "Ce champ doit être un nombre";
      },
    };
  },
  methods: {
    validate() {
      if (this.$refs.form.validate() && this.tokenApi == this.tokenCompare) {
        this.dialog = true;
      } else if (this.tokenApi != this.tokenCompare) {
        this.alert = true;
      }
    },
    miseAJour() {
      this.loading = true;
      const champ = {
        apiFacebook: this.tokenApi,
        idFacebook: this.idFacebook,
        idInstagram: this.idInstagram,
        publieFacebook: this.publieFacebook,
        publieInstagram: this.publieInstagram,
        updatedDate: new Date(),
        updatedBy: firebaseForAuth.auth().currentUser.uid,
        deletedBy: null,
        deletedDate: null,
      };

      if (this.image !== null) {
        firebase
          .storage()
          .ref("general_settings/mercanews/logo.png")
          .put(this.image)
          .then(() => {
            firebase
              .storage()
              .ref("general_settings/mercanews/logo.png")
              .getDownloadURL()
              .then((url) => {
                const champImage = {
                  lien: url,
                };
                db.collection(NodesName.GENERAL_SETTINGS)
                  .doc(NodesName.LOGOMERCANEWS)
                  .set(champImage)
                  .then(() => {
                    db.collection(NodesName.GENERAL_SETTINGS)
                      .doc(NodesName.SOCIAL_MEDIA)
                      .set(champ)
                      .then(() => {
                        this.dialog = false;
                        this.alert = false;
                        this.loading = false;
                      });
                  });
              });
          });
      } else {
        db.collection(NodesName.GENERAL_SETTINGS)
          .doc(NodesName.SOCIAL_MEDIA)
          .set(champ)
          .then(() => {
            this.dialog = false;
            this.alert = false;
            this.loading = false;
          });
      }
    },
    validateChamp() {
      if (this.$refs.formulaire.validate()) {
        this.dialogueTaxe = true;
      }
    },
    //Fonction de mise à jour de la taxe
    updateTaxe() {
      this.loading = true;
      const field = {
        taxe_percent: Number(this.taxe),
        updatedDate: new Date(),
        updateBy: firebaseForAuth.auth().currentUser.uid,
        deletedBy: null,
        deletedDate: null,
      };
      db.collection(NodesName.GENERAL_SETTINGS)
        .doc(NodesName.MERCARUE)
        .set(field)
        .then(() => {
          this.dialogueTaxe = false;
          this.loading = false;
        });
    },
    updatePAYMEE() {
      this.loading = true;
      db.collection(NodesName.GENERAL_SETTINGS)
        .doc(NodesName.PAYMENT_SOLUTION)
        .update({
          API_KEY: this.PaymeeAPI,
          accountNumber: Number(this.NumAccount),
          PaymentGatewayLink: this.PaymentGatewayLink,
          checkPaymentLink: this.PaymentGatewayLink,
          initiatePaymentLink: this.PaymentGatewayLink,
          payementFileLink: this.PaymentGatewayLink,
          payementResultFileLink: this.PaymentGatewayLink,
          updatedDate: new Date(),
          updateBy: firebaseForAuth.auth().currentUser.uid,
          deletedBy: null,
          deletedDate: null,
        })
        .then(() => {
          this.dialoguePaymee = false;
          this.loading = false;
        });
    },
  },
  async created() {
    var socialMedia = await FirebaseDB.GetSocialMediaInformations();
    var Paymeeaccount = await FirebaseDB.GetPAYMEEInformations();
    this.PaymeeAPI = Paymeeaccount.APIKEY;
    this.NumAccount = Paymeeaccount.accountNumber;
    this.PaymentGatewayLink = Paymeeaccount.PaymentGatewayLink;
    this.checkPaymentLink = Paymeeaccount.checkPaymentLink;
    this.initiatePaymentLink = Paymeeaccount.initiatePaymentLink;
    this.payementFileLink = Paymeeaccount.payementFileLink;
    this.payementResultFileLink = Paymeeaccount.payementResultFileLink;
    this.tokenApi = socialMedia.token;
    this.tokenCompare = socialMedia.token;
    this.logoMercanews = await FirebaseDB.GetLogoMercanews();
    this.idFacebook = socialMedia.idFacebook;
    this.idInstagram = socialMedia.idInstagram;
    this.publieFacebook = socialMedia.publieFacebook;
    this.publieInstagram = socialMedia.publieInstagram;
    this.taxe = await FirebaseDB.getTaxe();
  },
};
</script>
